import { ApiResponse } from "./api";
import { DealProperties } from "./deal/deal";
import { JaNeinValue } from "./deal/enums";

export enum SurveyFinancingType {
    REAL_ESTATE_CREDIT = "Immobilienfinanzierung",
    CONSUMER_CREDIT = "Konsumkredit",
    COMMERCIAL_CREDIT = "Gewerblich",
}

export enum SurveyPropertyUsage {
    OWN_USE = "Eigennutzung",
    RENT_OUT = "Zur Vermietung",
    COMMERCIAL = "Gewerblich",
}

export enum SurveyPurchaseProjectStatus {
    PROPERTY_SEARCH = "Immobiliensuche gestartet",
    ACTIVE_ON_SIGHTSEEING = "Aktiv auf Besichtigungen",
    WANT_TO_MAKE_AN_OFFER = "Ich will ein Kaufanbot stellen",
    PURCHASE_OFFER_ALREADY_MADE = "Kaufanbot schon gestellt",
    GETTING_INFORMATION = "Ich informiere mich nur",
}

export enum SurveyConstructionProjectStatus {
    LAND_AREA_AVAILABLE = "Grundstück vorhanden",
    LOOKING_FOR_A_PROPERTY = "Auf der Suche nach einem Grundstück",
}

export enum SurveyRefinancingProjectStatus {
    GETTING_INFORMATION = "Informiere mich ob es sich auszahlt",
    EXPLORING_CURRENT_INTEREST_RATES = "Nur aktuelle Zinsen erfahren",
    DEFINITELY_REFINANCING = "Ich will definitiv umschulden",
}

export enum SurveyCustomerIntentType {
    WRONG = "wrong",
    LOW = "low",
    HIGH = "high",
    MEDIUM = "medium",
    UNFEASIBLE = "unfeasible",
    FORECAST_LOW = "forecastLow",
    FORECAST_HIGH = "forecastHigh",
}

export type SurveyProjectStatus =
    | SurveyPurchaseProjectStatus
    | SurveyConstructionProjectStatus
    | SurveyRefinancingProjectStatus;

export type SurveyCookies = {
    hs_facebook_click_id: string;
    hs_google_click_id: string;
    user_id: string;
    utm_campaign: string;
    utm_content: string;
    utm_medium: string;
    utm_source: string;
    utm_term: string;
};

export const COOKIE_KEYS: (keyof SurveyCookies)[] = [
    "hs_facebook_click_id",
    "hs_google_click_id",
    "user_id",
    "utm_campaign",
    "utm_content",
    "utm_medium",
    "utm_source",
    "utm_term",
];

export enum SurveyConstructionSubmissionPlanReady {
    YES = "Ja",
    FAST = "Fast fertig",
    NO = "Nein, noch nicht gestartet",
}

export enum SurveyConstructionCostsAlreadyAvailable {
    YES = "Ja, ist vorhanden",
    FAST = "Fast fertig",
    NO = "Nein",
}

export enum SurveyConstructionEstimateRecieved {
    YES = "Ja, sind eingeholt",
    PARTIALY = "Ja, teilweise",
    NO = "Nein noch gar nicht",
}

export enum SurveyFinancingCompletion {
    AS_SOON_AS_POSSIBLE = "So schnell wie möglich",
    ONE_TO_THREE_MONTHS = "1-3 Monate",
    NOT_SURE = "Bin mir noch nicht sicher",
}

export enum SurveyHouseBankFinancingPreference {
    NONE = "Komplett egal",
    WANTED = "Gewünscht, aber nicht wichtig",
    DESIRED = "Sehr gewünscht",
}

export enum SurveyNumberOfBorrowers {
    ONE = "1 Person",
    TWO = "2 Personen",
    THREE = "3 Personen",
}

export enum SurveyDebtRestructuringPeroid {
    AS_SOON_AS_POSSIBLE = "So schnell wie möglich",
    ONE_TO_THREE_MONTHS = "1-3 Monate",
    NOT_SURE = "Bin mir noch nicht sicher",
}

export enum SurveyPurchaseProgress {
    HELD_CONVERSATION_WITH_BANKS = "Bankgespräche geführt",
    OFFERS_RECEIVED = "Angebote erhalten",
    BANK_APPOINTMENTS_PENDING = "Banktermin(e) ausständig",
    NO_CONVERSATIONS_HELD = "Noch keine Gespräche geführt",
}

export enum SurveyHowSoonFinancingCommitmentNeededPurchase {
    NOT_YET_PURCHASE_NOT_CONFIRMED = "Noch gar nicht, weil Kauf noch nicht fix",
    ASAP_WANT_TO_MAKE_AN_OFFER = "Schnellstmöglich, ich will gleich ein Kaufanbot legen",
    ASAP_ALREADY_MADE_AN_OFFER = "Schnellstmöglich, ich habe schon ein Kaufanbot gelegt",
}

export enum SurveyHowSoonFinancingCommitmentNeededRefinancing {
    NOT_YET_REFINANCING_NOT_CONFIRMED = "Noch gar nicht, weil Umschuldung noch nicht fix",
    ASAP_WANT_TO_REFINANCE = "Schnellstmöglich, ich will definitiv umschulden",
}

export enum SurveyHowCanWeHelpYou {
    CALCULATE_MONTHLY_RATE = "Monatliche Rate errechnen",
    WHICH_PURCHASE_PRICE_IS_AFFORDABLE = "Welcher Kaufpreis ist leistbar",
    VIEW_TOP_CONDITIONS = "Top-Konditionen einsehen",
    OBTAIN_FINANCING_OFFERS = "Finanzierungsangebote einholen",
    OTHER = "Sonstiges",
}

export enum SurveyNextStepsInPurchaseProcess {
    PURCHASE_OFFER_ALREADY_MADE = "Kaufanbot schon gestellt",
    WANT_TO_MAKE_AN_OFFER = "Ich will ein Kaufanbot stellen",
    STILL_THINKING_ABOUT_IT = "Noch am überlegen",
    NEGOTIATING_PURCHASE_PRICE = "Kaufpreisverhandlung",
    OTHER = "Sonstiges",
}

export enum SurveyPropertyAlreadyVisited {
    YES = "Ja",
    NO_BUT_HAVE_VIEW_APPOINTMENT = "Nein, aber Besichtigungstermin ist fixiert",
    NO = "Nein",
}

export enum SurveyPropertyAlreadyDecided {
    YES = "Ja",
    NO_NOT_YET = "Nein, noch nicht",
}

export enum SurveyBankOption {
    ERSTE = "Erste Bank",
    RAIFFEISEN = "Raiffeisenbank",
    UNICREDIT = "UniCredit Bank Austria",
    BAWAG = "BAWAG P.S.K.",
    SPARKASSE = "Sparkasse",
    HYPO = "HYPO",
    BANK99 = "Bank99",
    OBERBANK = "Oberbank",
    VOLKSBANK = "Volksbank",
    WUESTENROT = "Wüstenrot",
    RAIFEISSEN_BAU = "Raiﬀeisen Bausparkasse",
    SBAU = "sBausparkasse",
    START_BAU = "start:bausparkasse",
    OTHER = "Sonstiges",
}

export type SurveyCustomerIntentProperties = {
    intention: string;
    progress: string;
    usage: string;
    financingType: string;
    ltv: number;
    dsti: number;
    cooperativeApartment: string;
    currentInterestRate: number;
    referral: string;
    propertyAlreadyVisited: string;
    loanAmount: number;
    refinancingDebtLeftAmount: number;
    howSoonFinancingCommitmentNeeded: string;
    nextStepsInPurchaseProcess: string;
    roughConstructionStartDate: string;
    purchaseProgress: string;
    submissionPlanReady: string;
    houseBankFinancingPreference: string;
    constructionCostsAlreadyAvailable: string;
    constructionEstimateRecieved: string;
};

export type SurveyProperties = SurveyCustomerIntentProperties & {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    consentedMarketingEmails: string;
    refinancingDebtRemainingYears: number;
    constructionCosts: number;
    constructionIncludesLandPurchase: string;
    landPurchasePrice: number;
    availableCapital: number;
    price: number;
    estateAgentFee: number;
    purchaseContractCreationFee: number;
    realEstateTransferFee: number;
    propertyRegistrationFee: number;
    surveyVersion: string;
    propertyAlreadyDecided: string;
    propertyAlreadyFound: string;
    customerIntent: string;
    oldCustomerIntent: string;
    goalOfRefinancing: string;
    realEstate: string;
    solutionId: string;
    bookAppointmentDate: string;
    bookAppointmentSlot: string;
    // Construction additional details
    untilWhenFinancingCompleted: string;

    whenWasPropertyBought: string;
    currentMonthlyRate: number;
    currentCreditBank: string;
    talkedToLendingBank: string;

    propertyConstructionYear: number;
    propertyLivingSpaceArea: number;
    propertyLandArea: number;
    propertyValueEstimate: number;
    taskName: string;
    dealCreateHandled: string;
    propertyCompleted: string;

    topCondition: number;

    createAppointmentData: string;

    /* Information about the user sources */
    userSource: string;
    userSourceVersion: string;

    whichBanksAreHouseBanks: string;
    whichBanksAreHouseBanksOther: string;
    howCanWeHelpYou: string;
    howCanWeHelpYouOther: string;
    additionalInvestmentCosts: number;
    valueOfExistingProperty: number;
    existingPropertyZipcode: number;
    existingPropertyOutstandingLoanAmount: number;
    nextStepsInPurchaseProcessOther: string;

    pax: string;
    occupation: string;
    secondaryPersonOccupation: string;
    ternaryPersonOccupation: string;
    primaryPersonIncome: number;
    secondaryPersonIncome: number;
    ternaryPersonIncome: number;
    primaryPersonNumberOfSalaries: number;
    secondaryPersonNumberOfSalaries: number;
    ternaryPersonNumberOfSalaries: number;
    variant: string;
    originalPurchaseOrConstructionPrice: number;
    purchasePlannedAt: string;
    hasExistingBankOffer: JaNeinValue;
    existingBankOfferPayoutAmount: number;
    existingBankOfferDocumentPath: string;
    salutation: string;
} & SurveyCookies;

export type SurveyPropertiesKey = keyof SurveyProperties;

export const SURVEY_PROPERTIES_TO_HUBSPOT_CONTACT_FIELDS_MAP: Record<SurveyPropertiesKey, string> = {
    usage: "art_der_immobiliennutzung",
    intention: "kaufvorhaben",
    progress: "need",
    financingType: "art_der_finanzierung",
    realEstate: "immobilieneigentum_in_der_familie_vorhanden",
    firstName: "firstname",
    lastName: "lastname",
    phone: "phone",
    email: "email",
    consentedMarketingEmails: "einwilligung_fur_marketing_e_mails",
    loanAmount: "kreditbetrag",
    refinancingDebtLeftAmount: "restschuld_hypothekardarlehen",
    refinancingDebtRemainingYears: "bestehende_kreditlaufzeit",
    constructionCosts: "baukosten",
    constructionIncludesLandPurchase: "grundstuck_mitfinanzieren",
    landPurchasePrice: "kaufpreis_grundstuck",
    availableCapital: "eigenkapital",
    price: "kaufpreis_der_immobilie",
    estateAgentFee: "vermittlungsgebuhr_immobilientreuhander",
    realEstateTransferFee: "grunderwerbssteuer_finanzamt",
    propertyRegistrationFee: "grundbucheintragungsgebuhr",
    purchaseContractCreationFee: "kaufvertragserrichtungsgebuhr_inkl__treuhandschaft",
    ltv: "ltv__survey_",
    dsti: "dsti__survey_",
    surveyVersion: "survey_version",
    propertyAlreadyVisited: "immobilie_schon_besichtigt",
    propertyAlreadyDecided: "propertyalreadydecided",
    untilWhenFinancingCompleted: "bis_wann_finanzierung_stehen",
    houseBankFinancingPreference: "housebankfinancingpreference",
    submissionPlanReady: "einreichplan_fertig",
    constructionCostsAlreadyAvailable: "baukostenaufstellung_schon_vorhanden",
    constructionEstimateRecieved: "kostenvoranschlage_schon_eingeholt",
    roughConstructionStartDate: "ungefahrer_baubeginntermin",
    whenWasPropertyBought: "umschuldung_wann_gekauft",
    currentMonthlyRate: "umschuldung_aktuelle_rate",
    currentInterestRate: "umschuldung_aktueller_nominalzins",
    currentCreditBank: "umschuldung_kreditgebende_bank",
    talkedToLendingBank: "umschuldung_mit_kreditgebenden_bank_gesprochen",
    referral: "referral_source",

    propertyConstructionYear: "haus_baujahr",
    propertyLivingSpaceArea: "wohnflache_immobilie",
    propertyLandArea: "grundstucksflache_immobilie",
    propertyValueEstimate: "schatzwert_der_immobilie",
    customerIntent: "customer_intent",
    oldCustomerIntent: "old_customer_intent",

    user_id: "user_id",
    hs_google_click_id: "hs_google_click_id",
    hs_facebook_click_id: "hs_facebook_click_id",
    utm_campaign: "utm_campaign",
    utm_content: "utm_content",
    utm_medium: "utm_medium",
    utm_source: "utm_source",
    utm_term: "utm_term",
    taskName: "",
    dealCreateHandled: "",

    cooperativeApartment: "cooperative_apartment",
    propertyCompleted: "property_completed",
    topCondition: "survey_top_condition",

    createAppointmentData: "",
    goalOfRefinancing: "user_comment",

    userSource: "user_source",
    userSourceVersion: "user_source_version",

    purchaseProgress: "finanzierungsprozess",
    howSoonFinancingCommitmentNeeded: "wie_schnell_finanzierungszusage_gebraucht",
    whichBanksAreHouseBanks: "hausbanken",
    whichBanksAreHouseBanksOther: "hausbanken_sonstige",
    propertyAlreadyFound: "immobilie_gefunden",
    howCanWeHelpYou: "wie_konnen_wir_dir_helfen_",
    howCanWeHelpYouOther: "wie_konnen_wir_dir_helfen___sonstige_",
    additionalInvestmentCosts: "zusatzliche_investionskosten",
    valueOfExistingProperty: "wert_der_zusatzliche_immobilie_",
    existingPropertyZipcode: "plz_bestandsobjekt",
    existingPropertyOutstandingLoanAmount: "zusatzliche_immobilie_aushaftende_kredit",
    nextStepsInPurchaseProcess: "nachsten_schritte_im_kaufprozess",
    nextStepsInPurchaseProcessOther: "nachsten_schritte_im_kaufprozess_sonstige",

    pax: "anzahl_kn",
    occupation: "job_position",
    secondaryPersonOccupation: "job_position_kn2",
    ternaryPersonOccupation: "job_position_kn3",
    primaryPersonNumberOfSalaries: "anzahl_der_gehalter",
    secondaryPersonNumberOfSalaries: "anzahl_der_gehalter_kn2",
    ternaryPersonNumberOfSalaries: "anzahl_der_gehalter_kn3",
    primaryPersonIncome: "monatliches_nettoeinkommen",
    secondaryPersonIncome: "monatliches_nettoeinkommen_kn2",
    ternaryPersonIncome: "monatliches_nettoeinkommen_kn3",
    variant: "",
    purchasePlannedAt: "bis_wann_soll_der_kauf_stattfinden__survey",
    originalPurchaseOrConstructionPrice: "ursprunglicher_kaufpreis_bzw__baukosten",
    solutionId: "",
    bookAppointmentDate: "",
    bookAppointmentSlot: "",
    hasExistingBankOffer: "",
    existingBankOfferPayoutAmount: "bestehender_bankangebots_auszahlungsbetrag__survey",
    existingBankOfferDocumentPath: "vorhandenes_bankangebotsdokument__survey",
    salutation: "anrede_kn1",
};

export const SURVEY_PROPERTIES_TO_HUBSPOT_DEAL_FIELDS_MAP: Record<SurveyPropertiesKey, string> = {
    usage: "art_der_immobiliennutzung__survey_",
    constructionCostsAlreadyAvailable: "baukostenaufstellung_schon_vorhanden",
    roughConstructionStartDate: "ungefahrer_baubeginntermin",
    houseBankFinancingPreference: "surveyhousebankfinancingpreference",
    untilWhenFinancingCompleted: "bis_wann_finanzierung_stehen",
    dsti: "dsti__survey_",
    availableCapital: "eigenkapital",
    submissionPlanReady: "einreichplan_fertig",
    email: "email_kn1",
    consentedMarketingEmails: "einwilligung_fur_marketing_e_mails",
    constructionEstimateRecieved: "kostenvoranschlage_schon_eingeholt",
    firstName: "first_name",
    propertyRegistrationFee: "grundbucheintragungsgebuhr",
    realEstateTransferFee: "grunderwerbssteuer",
    phone: "handynummer_kn1",
    propertyAlreadyVisited: "immobilie_schon_besichtigt",
    propertyAlreadyDecided: "surveypropertyalreadydecided",
    realEstate: "immobilieneigentum_in_der_familie",
    price: "kaufpreis",
    purchaseContractCreationFee: "kaufvertragserrichtungsgebuhr_inkl__treuhandschaft",
    intention: "kaufvorhaben__survey_",
    loanAmount: "kreditbetrag",
    lastName: "last_name",
    ltv: "ltv__survey_",
    progress: "need_of_customer",
    refinancingDebtLeftAmount: "restschuld_hypothekardarlehen",
    financingType: "survey_art_der_finanzierung",
    surveyVersion: "survey_version",
    currentMonthlyRate: "umschuldung_aktuelle_rate",
    currentInterestRate: "umschuldung_aktueller_nominalzins",
    currentCreditBank: "umschuldung_kreditgebende_bank",
    refinancingDebtRemainingYears: "umschuldung_restlaufzeit",
    whenWasPropertyBought: "umschuldung_wann_gekauft",
    estateAgentFee: "vermittlungsgebuhr_immobilientreuhander",
    talkedToLendingBank: "umschuldung_mit_kreditgebenden_bank_gesprochen",
    customerIntent: "customer_intent",
    oldCustomerIntent: "old_customer_intent",

    user_id: "user_id",
    hs_google_click_id: "gclid",
    hs_facebook_click_id: "fbclid",
    utm_campaign: "utm_campaign",
    utm_content: "utm_content",
    utm_medium: "utm_medium",
    utm_source: "utm_source",
    utm_term: "utm_term",

    constructionCosts: "baukosten",
    constructionIncludesLandPurchase: "grundstuck_mitfinanzieren",
    landPurchasePrice: "kaufpreis_grundstuck",

    propertyConstructionYear: "baujahr",
    propertyLandArea: "haus_grundstucksflache",
    propertyLivingSpaceArea: "wohnflache",
    propertyValueEstimate: "schatzwert_der_immobilie",
    referral: "referral_source",
    taskName: "",
    dealCreateHandled: "",

    cooperativeApartment: "surveycooperativeapartment",
    propertyCompleted: "fertiggestellt",
    topCondition: "survey_top_condition",

    createAppointmentData: "",
    goalOfRefinancing: "user_comment",
    userSource: "user_source",
    userSourceVersion: "user_source_version",

    purchaseProgress: "finanzierungsprozess_survey",
    howSoonFinancingCommitmentNeeded: "wie_schnell_finanzierungszusage_gebraucht_survey",
    whichBanksAreHouseBanks: "hausbanken_survey",
    whichBanksAreHouseBanksOther: "hausbanken_sonstige_survey",
    propertyAlreadyFound: "immobilie_gefunden_survey",
    howCanWeHelpYou: "wie_konnen_wir_dir_helfen__survey",
    howCanWeHelpYouOther: "wie_konnen_wir_dir_helfen___sonstige__survey",
    additionalInvestmentCosts: "zusatzliche_investionskosten_survey",
    valueOfExistingProperty: "wert_der_zusatzliche_immobilie_survey",
    existingPropertyZipcode: "plz_bestandsobjekt",
    existingPropertyOutstandingLoanAmount: "zusatzliche_immobilie_aushaftende_kredit_survey",
    nextStepsInPurchaseProcess: "nachsten_schritte_im_kaufprozess_survey",
    nextStepsInPurchaseProcessOther: "nachsten_schritte_im_kaufprozess_sonstige_survey",

    pax: "anzahl_kn",
    occupation: "anstellungsverhaltnis__survey_",
    secondaryPersonOccupation: "anstellungsverhaltnis_kn2__survey_",
    ternaryPersonOccupation: "anstellungsverhaltnis_kn3__survey_",
    primaryPersonNumberOfSalaries: "anzahl_der_gehalter_kn1",
    secondaryPersonNumberOfSalaries: "anzahl_der_gehalter_kn2",
    ternaryPersonNumberOfSalaries: "anzahl_der_gehalter_kn3",
    primaryPersonIncome: "nettogehalt",
    secondaryPersonIncome: "nettogehalt_kn2",
    ternaryPersonIncome: "nettogehalt_kn3",
    variant: "",
    purchasePlannedAt: "bis_wann_soll_der_kauf_stattfinden__survey",
    originalPurchaseOrConstructionPrice: "ursprunglicher_kaufpreis_bzw__baukosten",
    solutionId: "",
    bookAppointmentDate: "",
    bookAppointmentSlot: "",
    hasExistingBankOffer: "",
    existingBankOfferPayoutAmount: "bestehender_bankangebots_auszahlungsbetrag__survey",
    existingBankOfferDocumentPath: "vorhandenes_bankangebotsdokument__survey",
    salutation: "anrede",
};

export const SURVEY_PROPERTIES_TO_DEAL_PROPERTIES_MAP: Record<SurveyPropertiesKey, keyof DealProperties | undefined> = {
    availableCapital: "downPayment",
    currentInterestRate: "currentInterestRate",
    customerIntent: "customerIntent",
    oldCustomerIntent: "oldCustomerIntent",
    email: "emailBorrower1",
    consentedMarketingEmails: "surveyConsentedMarketingEmails",
    estateAgentFee: "estateAgentFee",
    financingType: "surveyFinancingType",
    intention: "propertyFinancingType",
    firstName: "firstNameBorrower1",
    lastName: "lastNameBorrower1",
    loanAmount: "loanAmount",
    phone: "phoneNumberBorrower1",
    price: "purchasePrice",
    progress: "needOfCustomerSurvey",
    propertyAlreadyVisited: "surveyPropertyAlreadyVisited",
    propertyAlreadyDecided: "surveyPropertyAlreadyDecided",
    propertyRegistrationFee: "landRegistryEntryFee",
    propertyConstructionYear: "propertyConstructionYear",
    propertyLivingSpaceArea: "propertyLivingSpaceArea",
    propertyLandArea: "propertyLandArea",
    purchaseContractCreationFee: "purchaseContractCreationFee",
    realEstateTransferFee: "realEstateTransferFee",
    referral: "referral",
    houseBankFinancingPreference: "surveyHouseBankFinancingPreference",
    untilWhenFinancingCompleted: "surveyUntilWhenFinancingCompleted",
    roughConstructionStartDate: "surveyRoughConstructionStartDate",
    goalOfRefinancing: "surveyGoalOfRefinancing",
    usage: "surveyUsage",
    topCondition: "surveyTopCondition",
    talkedToLendingBank: "talkedToLendingBank",
    realEstate: "surveyRealEstateOwned",
    cooperativeApartment: "surveyCooperativeApartment",
    propertyCompleted: "propertyCompleted",
    constructionCosts: "propertyConstructionCosts",
    ltv: "ltv",
    dsti: "dsti",
    salutation: "salutationBorrower1",

    refinancingDebtLeftAmount: "surveyRefinancingDebtLeftAmount",
    refinancingDebtRemainingYears: "surveyRefinancingDebtRemainingYears",
    constructionIncludesLandPurchase: "surveyConstructionIncludesLandPurchase",
    landPurchasePrice: "surveyLandPurchasePrice",
    surveyVersion: "surveyVersion",
    submissionPlanReady: "surveySubmissionPlanReady",
    constructionCostsAlreadyAvailable: "surveyConstructionCostsAlreadyAvailable",
    constructionEstimateRecieved: "surveyConstructionEstimateRecieved",
    whenWasPropertyBought: "surveyWhenWasPropertyBought",
    currentMonthlyRate: "surveyCurrentMonthlyRate",
    currentCreditBank: "surveyCurrentCreditBank",
    propertyValueEstimate: "surveyPropertyValueEstimate",
    purchaseProgress: "surveyPurchaseProgress",
    howSoonFinancingCommitmentNeeded: "surveyHowSoonFinancingCommitmentNeeded",
    whichBanksAreHouseBanks: "surveyWhichBanksAreHouseBanks",
    whichBanksAreHouseBanksOther: "surveyWhichBanksAreHouseBanksOther",
    propertyAlreadyFound: "surveyPropertyAlreadyFound",
    howCanWeHelpYou: "surveyHowCanWeHelpYou",
    howCanWeHelpYouOther: "surveyHowCanWeHelpyouOther",
    additionalInvestmentCosts: "totalConstructionCosts",
    valueOfExistingProperty: "surveyValueOfExistingProperty",
    existingPropertyZipcode: "surveyExistingPropertyZipcode",
    existingPropertyOutstandingLoanAmount: "surveyExistingPropertyOutstandingLoanAmount",
    nextStepsInPurchaseProcess: "surveyNextStepsInPurchaseProcess",
    nextStepsInPurchaseProcessOther: "surveyNextStepsInPurchaseProcessOther",
    solutionId: "surveySolutionId",
    bookAppointmentDate: "surveyBookAppointmentDate",
    bookAppointmentSlot: "surveyBookAppointmentSlot",

    occupation: "employmentTypeBorrower1",
    secondaryPersonOccupation: "employmentTypeBorrower2",
    ternaryPersonOccupation: "employmentTypeBorrower3",
    primaryPersonNumberOfSalaries: "numberOfSalariesBorrower1",
    secondaryPersonNumberOfSalaries: "numberOfSalariesBorrower2",
    ternaryPersonNumberOfSalaries: "numberOfSalariesBorrower3",
    primaryPersonIncome: "monthlyNetIncomeBorrower1",
    secondaryPersonIncome: "monthlyNetIncomeBorrower2",
    ternaryPersonIncome: "monthlyNetIncomeBorrower3",
    purchasePlannedAt: "surveyPurchasePlannedAt",
    originalPurchaseOrConstructionPrice: "surveyOriginalPurchaseOrConstructionPrice",
    hs_facebook_click_id: "hs_facebook_click_id",
    hs_google_click_id: "hs_google_click_id",
    user_id: "user_id",
    utm_campaign: "utm_campaign",
    utm_content: "utm_content",
    utm_medium: "utm_medium",
    utm_source: "utm_source",
    utm_term: "utm_term",
    userSource: "userSource",
    userSourceVersion: "userSourceVersion",
    taskName: undefined,
    dealCreateHandled: undefined,
    createAppointmentData: undefined,
    pax: undefined,
    variant: undefined,
    hasExistingBankOffer: "surveyHasExistingBankOffer",
    existingBankOfferPayoutAmount: "surveyExistingBankOfferPayoutAmount",
    existingBankOfferDocumentPath: "surveyExistingBankOfferDocumentPath",
};

/**
 * Since MOR-2004, we needed to add a lot more of these surveySomething fields that are read-only for the CE1 tab
 * To address that and edit the mapper methods, we introduce a map of additional fields that both have mappings in
 * deal properties we use that are overwriteable, and some that are read only here for display on the top of the CE1 tab
 */
export const READ_ONLY_SURVEY_TO_DEAL_PROPERTIES_MAP: Partial<Record<keyof SurveyProperties, keyof DealProperties>> = {
    loanAmount: "surveyLoanAmount",
    price: "surveyPurchasePrice",
    availableCapital: "surveyDownPayment",
    ltv: "ltvSurvey",
    dsti: "dstiSurvey",
    additionalInvestmentCosts: "surveyAdditionalInvestmentCosts",
    refinancingDebtLeftAmount: "surveyRefinancingDebtLeftAmount",
    intention: "surveyIntention",
    constructionCosts: "surveyConstructionCosts",
    primaryPersonIncome: "surveyPrimaryPersonIncome",
    occupation: "surveyPrimaryPersonOccupation",
};

export type CreateOrUpdateContactResponseData = {
    contactId: number;
    created: string;
    email: string;
    isExistingContact: boolean;
};

export type PostEmailValidationResponseData = {
    email: string;
};

export type CreateOrUpdateContactResponse = ApiResponse<CreateOrUpdateContactResponseData>;

export enum SurveyCustomerWrongIntentTypeReason {
    OTHER_PROJECT_TYPE = "OTHER_PROJECT_TYPE",
    COMMERCIAL_USAGE = "COMMERCIAL_USAGE",
    COMMERCIAL_CREDIT = "COMMERCIAL_CREDIT",
    CONSUMER_CREDIT = "CONSUMER_CREDIT",
    MISCELLANEOUS = "MISCELLANEOUS",
}
export enum SurveyCustomerLowIntentTypeReason {
    LTV = "LTV",
    DSTI = "DSTI",
    NO_VISIT_OR_COMPLETE = "NO_VISIT_OR_COMPLETE",
    MISCELLANEOUS = "MISCELLANEOUS",
}

export type SurveyCustomerIntentTypeReturn = {
    intentType: SurveyCustomerIntentType;
    reason?: SurveyCustomerWrongIntentTypeReason | SurveyCustomerLowIntentTypeReason;
};
