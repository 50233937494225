import {
    ALL_HUBSPOT_DEAL_PROPERTIES_MAP,
    DEAL_PROPERTIES_TO_HUBSPOT_CONTACT_MAP,
    DEFAULT_SURVEY_PROPERTIES_VALUES,
    HIGH_DSTI_MARGIN,
    HIGH_LTV_MARGIN,
    NUMBER_OF_BORROWERS_OPTION_TO_COUNT_MAP,
    SURVEY_USAGE_TO_DEAL_USAGE_MAP,
} from "../constants";

import { parsePhoneNumber } from "libphonenumber-js";

import {
    COOKIE_KEYS,
    SURVEY_PROPERTIES_TO_DEAL_PROPERTIES_MAP,
    READ_ONLY_SURVEY_TO_DEAL_PROPERTIES_MAP,
    SURVEY_PROPERTIES_TO_HUBSPOT_CONTACT_FIELDS_MAP,
    SURVEY_PROPERTIES_TO_HUBSPOT_DEAL_FIELDS_MAP,
    SurveyConstructionProjectStatus,
    SurveyCookies,
    SurveyCustomerIntentType,
    SurveyCustomerIntentTypeReturn,
    SurveyCustomerLowIntentTypeReason,
    SurveyCustomerWrongIntentTypeReason,
    SurveyFinancingCompletion,
    SurveyFinancingType,
    SurveyProperties,
    SurveyCustomerIntentProperties,
    SurveyPropertyUsage,
    SurveyPurchaseProjectStatus,
    BorrowerReferralType,
    DealProperties,
    DealPropertyFinancingType,
    JaNeinValue,
    SurveySubmitResponseCode,
    SurveyNextStepsInPurchaseProcess,
    SurveyHowSoonFinancingCommitmentNeededPurchase,
    SurveyNumberOfBorrowers,
    SurveyPropertyAlreadyVisited,
    SurveyPurchaseProgress,
    SurveyConstructionSubmissionPlanReady,
    SurveyPropertiesKey,
    DealPropertiesKey,
    SurveyHouseBankFinancingPreference,
    SurveyConstructionCostsAlreadyAvailable,
    SurveyConstructionEstimateRecieved,
} from "../models";
import { generateRoughConstructionDates, isHighCustomerIntentGroup } from "./survey";

export type PropertyEntry = {
    property: string;
    value: string | number | boolean;
};

export type AnyProjectIntentType = "any";
export type UserProjectIntentType = DealPropertyFinancingType | AnyProjectIntentType;

export const CUSTOMER_HIGH_INTENT_REFINANCING_INTEREST_RATE_THRESHOLD = 4;
export const OLD_CUSTOMER_HIGH_INTENT_REFINANCING_INTEREST_RATE_THRESHOLD = 5.25;
export const MIN_LOW_INTENT_LOAN_AMOUNT = 50000;
export const MIN_REFINANCING_LOW_INTENT_LOAN_AMOUNT = 120_000;
export const MIN_FORECAST_LOW_INTENT_LOAN_AMOUNT = 250_000;

const SUBMISSION_PLAN_READY_OR_AVAILABLE_VALUES = [
    SurveyConstructionSubmissionPlanReady.YES,
    SurveyConstructionSubmissionPlanReady.FAST,
];

const CONSTRUCTION_COSTS_ALREADY_AVAILABLE_OR_ALMOST_READY = [
    SurveyConstructionCostsAlreadyAvailable.YES,
    SurveyConstructionCostsAlreadyAvailable.FAST,
];

const CONSTRUCTION_COSTS_ESTIMATE_STARTED_OR_FINISHED = [
    SurveyConstructionEstimateRecieved.PARTIALY,
    SurveyConstructionEstimateRecieved.YES,
];

/**
 * So the HIT start dates are the next 2 seasons, and the rest of them are "too late" (LIT)
 * To get them dynamically, just generate them as usual and slice the first 2 off
 */
const ROUGH_CONSTRUCTION_DATES = generateRoughConstructionDates();

// first 2 months
export const ON_SEASON_CONSTRUCTION_TIMELINE = ROUGH_CONSTRUCTION_DATES.slice(0, 2);

// remaining months after slicing the first 2
export const OFF_SEASON_CONSTRUCTION_TIMELINE = ROUGH_CONSTRUCTION_DATES.slice(2);

export const normalizePhoneNumber = (phoneNumber: string): string => {
    if (phoneNumber === null || phoneNumber === undefined || phoneNumber.trim() === "") {
        throw new Error(`${SurveySubmitResponseCode.PHONE_VALIDATION_ERROR} for invalid or empty input.`);
    }

    try {
        return parsePhoneNumber(phoneNumber, "AT").number as string;
    } catch (error) {
        throw new Error(`${SurveySubmitResponseCode.PHONE_VALIDATION_ERROR} for input ${phoneNumber}`);
    }
};

export const isValidPhoneNumber = (phoneNumber: string) => {
    try {
        normalizePhoneNumber(phoneNumber);
        return true;
    } catch (err) {
        return false;
    }
};

export const mapSurveyPropertiesToHubspotContactProperties = (
    surveyProperties: Partial<SurveyProperties>,
    cookies: Record<string, string>,
    keepOriginalMapping = false,
): PropertyEntry[] => {
    return mapSurveyPropertiesToHubspotProperties(
        surveyProperties,
        cookies,
        SURVEY_PROPERTIES_TO_HUBSPOT_CONTACT_FIELDS_MAP,
        [],
        keepOriginalMapping,
    );
};

export const mapSurveyPropertiesToHubspotDealProperties = (
    surveyProperties: Partial<SurveyProperties>,
    cookies: Record<string, string>,
    keepOriginalMapping = false,
): PropertyEntry[] => {
    return mapSurveyPropertiesToHubspotProperties(
        surveyProperties,
        cookies,
        SURVEY_PROPERTIES_TO_HUBSPOT_DEAL_FIELDS_MAP,
        [],
        keepOriginalMapping,
    );
};

const mapSurveyPropertiesToHubspotProperties = (
    surveyProperties: Partial<SurveyProperties>,
    cookies: Record<string, string>,
    propertyMapping: Partial<Record<SurveyPropertiesKey, string>>,
    additionalProps: PropertyEntry[] = [],
    keepOriginalMapping = false,
): PropertyEntry[] => {
    const props: PropertyEntry[] = [];

    Object.entries(surveyProperties || DEFAULT_SURVEY_PROPERTIES_VALUES)
        .filter(([key]) => !!propertyMapping[key as SurveyPropertiesKey])
        .forEach(([key, value]) => {
            if (keepOriginalMapping) {
                if (!props.find((prop) => prop.property === key)) {
                    props.push({
                        property: propertyMapping[key as SurveyPropertiesKey] as string,
                        value: value ?? DEFAULT_SURVEY_PROPERTIES_VALUES[key as SurveyPropertiesKey],
                    });
                }
            } else {
                switch (key) {
                    case "phone": {
                        props.push({
                            property: propertyMapping.phone as string,
                            value: normalizePhoneNumber(value as string),
                        });
                        break;
                    }
                    case "firstName": {
                        props.push({
                            property: propertyMapping.firstName as string,
                            value: (value as string)?.trim(),
                        });
                        break;
                    }
                    case "lastName":
                    case "propertyValueEstimate": {
                        break;
                    }
                    default: {
                        /* No special handling of property needed, just take over as it is */
                        if (!props.find((prop) => prop.property === key)) {
                            props.push({
                                property: propertyMapping[key as SurveyPropertiesKey] as string,
                                value: value ?? DEFAULT_SURVEY_PROPERTIES_VALUES[key as SurveyPropertiesKey],
                            });
                        }
                    }
                }
            }
        });

    if (additionalProps?.length) {
        props.push(...additionalProps);
    }

    if (cookies) {
        /* Add cookies for hubspot */
        Object.entries(cookies).forEach(([key, value]) => {
            if (!key || !COOKIE_KEYS.includes(key as keyof SurveyCookies)) {
                console.log(`sent invalid cookie key, ${key} : ${value}`);
                return;
            } else if (value == null || value === undefined) {
                console.log(`skipping field ${key} due to empty value`);
            } else if (!props.find((p) => p.property === propertyMapping[key as SurveyPropertiesKey])) {
                props.push({
                    property: propertyMapping[key as SurveyPropertiesKey] as string,
                    value,
                });
            }
        });
    }

    return props;
};

export const mapDealPropertiesToHubspotContactProperties = (
    dealProperties: Partial<DealProperties>,
): PropertyEntry[] => {
    return mapDealPropertiesToHubspotProperties(dealProperties, DEAL_PROPERTIES_TO_HUBSPOT_CONTACT_MAP);
};

export const mapDealPropertiesToHubspotDealProperties = (dealProperties: Partial<DealProperties>): PropertyEntry[] => {
    return mapDealPropertiesToHubspotProperties(dealProperties, ALL_HUBSPOT_DEAL_PROPERTIES_MAP);
};

const mapDealPropertiesToHubspotProperties = (
    dealProperties: Partial<DealProperties>,
    propertyMapping: Partial<Record<DealPropertiesKey, string>>,
): PropertyEntry[] => {
    const props: PropertyEntry[] = [];

    Object.entries(dealProperties)
        .filter(([key]) => !!propertyMapping[key as DealPropertiesKey])
        .forEach(([key, value]) => {
            if (!!value && !Array.isArray(value) && !props.find((prop) => prop.property === key)) {
                props.push({
                    property: propertyMapping[key as DealPropertiesKey] as string,
                    value,
                });
            }
        });

    return props;
};

const extendDealPropertiesWithSurveyDefaults = (dealProperties: DealProperties): DealProperties => {
    const { surveyIntention, surveyUsage } = dealProperties;

    const propertyFinancingType = surveyIntention as DealPropertyFinancingType;
    const propertyObjectPurpose = SURVEY_USAGE_TO_DEAL_USAGE_MAP[surveyUsage as SurveyPropertyUsage];

    return {
        ...dealProperties,
        propertyFinancingType,
        propertyObjectPurpose,
    };
};

const getAdditionalSurveyToDealProperties = (surveyProperties: Partial<SurveyProperties>): Partial<DealProperties> => {
    let dealProperties: Partial<DealProperties> = {};
    const { pax, intention, refinancingDebtRemainingYears = 0 } = surveyProperties;

    if (pax) {
        const numberOfBorrowers = NUMBER_OF_BORROWERS_OPTION_TO_COUNT_MAP[pax as SurveyNumberOfBorrowers];

        if (numberOfBorrowers) {
            dealProperties = {
                ...dealProperties,
                numberOfBorrowers,
            };
        }
    }

    if (intention === DealPropertyFinancingType.REFINANCE) {
        dealProperties = {
            ...dealProperties,
            purchasePrice: surveyProperties.refinancingDebtLeftAmount ?? 0,
            remainingMonths: refinancingDebtRemainingYears * 12,
        };
    }

    let readOnlyProperties = Object.entries(READ_ONLY_SURVEY_TO_DEAL_PROPERTIES_MAP).reduce(
        (resultDealProperties: DealProperties, [surveyProp, dealProp]) => {
            const surveyPropertiesKey = surveyProp as keyof SurveyProperties;
            const value = surveyProperties[surveyPropertiesKey];

            if (!dealProp || value == null || value === undefined) {
                return resultDealProperties;
            }

            return {
                ...resultDealProperties,
                [dealProp]: value,
            };
        },
        {} as DealProperties,
    );

    return { ...dealProperties, ...readOnlyProperties };
};

export const mapSurveyPropertiesToDealProperties = (
    surveyProperties: Partial<SurveyProperties>,
): Partial<DealProperties> => {
    const mappedProperties = Object.entries(SURVEY_PROPERTIES_TO_DEAL_PROPERTIES_MAP).reduce(
        (resultDealProperties: DealProperties, [surveyProp, dealProp]) => {
            const surveyPropertiesKey = surveyProp as SurveyPropertiesKey;
            const value = surveyProperties[surveyPropertiesKey];

            if (!dealProp || value == null || value === undefined) {
                return resultDealProperties;
            }

            return {
                ...resultDealProperties,
                [dealProp]: value,
            };
        },
        {} as DealProperties,
    );

    const additionalProperties = getAdditionalSurveyToDealProperties(surveyProperties);

    return extendDealPropertiesWithSurveyDefaults({
        ...mappedProperties,
        ...additionalProperties,
    });
};

export const mapDealPropertiesToSurveyProperties = (
    dealProperties: Partial<DealProperties>,
): Partial<SurveyProperties> => {
    const mappedProperties = Object.entries(SURVEY_PROPERTIES_TO_DEAL_PROPERTIES_MAP).reduce(
        (resultSurveyProperties: DealProperties, [surveyProp, dealProp]) => {
            const surveyPropertiesKey = surveyProp as SurveyPropertiesKey;
            const dealPropertiesKey = dealProp as keyof DealProperties;
            const value = dealProperties[dealPropertiesKey];

            if (!value) {
                return resultSurveyProperties;
            }

            return {
                ...resultSurveyProperties,
                [surveyPropertiesKey]: value,
            };
        },
        {} as DealProperties,
    );

    const numberOfBorrowers = dealProperties.numberOfBorrowers;
    if (numberOfBorrowers) {
        const pax = Object.entries(NUMBER_OF_BORROWERS_OPTION_TO_COUNT_MAP).find(
            ([, val]) => val === numberOfBorrowers,
        )?.[0]; // the key from the map
        return {
            ...mappedProperties,
            pax,
        };
    }

    return mappedProperties;
};

export const SURVEY_PURCHASE_PROJECT_RESEARCH_PROGRESS_VALUES = [
    SurveyPurchaseProjectStatus.PROPERTY_SEARCH,
    SurveyPurchaseProjectStatus.ACTIVE_ON_SIGHTSEEING,
    SurveyPurchaseProjectStatus.GETTING_INFORMATION,
];

export const SURVEY_PURCHASE_PROJECT_PURCHASE_PROGRESS_VALUES = [
    SurveyPurchaseProjectStatus.WANT_TO_MAKE_AN_OFFER,
    SurveyPurchaseProjectStatus.PURCHASE_OFFER_ALREADY_MADE,
];

export const SURVEY_PURCHASE_FINANCING_COMPLETION_VALUES = [
    SurveyFinancingCompletion.ONE_TO_THREE_MONTHS,
    SurveyFinancingCompletion.AS_SOON_AS_POSSIBLE,
];

export const SURVEY_PURCHASE_REFERRAL_VALUES = [
    BorrowerReferralType.MIRACL_WORKER,
    BorrowerReferralType.COOPERATION_PARTNER,
    BorrowerReferralType.FORMER_CUSTOMER,
];

/**
 * [MOR-1983] Helper function for determining the OLD intent type of customer.
 *
 * Based on https://mm.tt/app/map/3012985728?t=juY86zr1Pi
 *
 * @param {SurveyCustomerIntentProperties | SurveyProperties} surveyProperties all the necessary fields or all surveyProperties fields
 * at places
 * @return {SurveyCustomerIntentType}
 */
export const getOldCustomerIntentType = (
    surveyProperties: SurveyCustomerIntentProperties | SurveyProperties,
): SurveyCustomerIntentTypeReturn => {
    if (!surveyProperties) {
        return {
            intentType: SurveyCustomerIntentType.LOW,
        };
    }

    const {
        intention,
        progress,
        usage,
        financingType,
        ltv,
        dsti,
        cooperativeApartment,
        currentInterestRate,
        referral,
        propertyAlreadyVisited,
        refinancingDebtLeftAmount,
        loanAmount,
        howSoonFinancingCommitmentNeeded,
        nextStepsInPurchaseProcess,
        roughConstructionStartDate,
    } = surveyProperties;

    if (usage === SurveyPropertyUsage.COMMERCIAL) {
        return {
            intentType: SurveyCustomerIntentType.WRONG,
            reason: SurveyCustomerWrongIntentTypeReason.COMMERCIAL_USAGE,
        };
    }

    let intentType: SurveyCustomerIntentType | undefined = undefined;
    let reason: SurveyCustomerWrongIntentTypeReason | SurveyCustomerLowIntentTypeReason | undefined = undefined;

    switch (intention) {
        case DealPropertyFinancingType.CONSUMER_CREDIT: {
            intentType = SurveyCustomerIntentType.WRONG;
            reason = SurveyCustomerWrongIntentTypeReason.CONSUMER_CREDIT;
            break;
        }
        case DealPropertyFinancingType.OTHER: {
            intentType = SurveyCustomerIntentType.WRONG;
            reason = SurveyCustomerWrongIntentTypeReason.OTHER_PROJECT_TYPE;
            break;
        }
        case DealPropertyFinancingType.REFINANCE: {
            switch (financingType) {
                case SurveyFinancingType.COMMERCIAL_CREDIT: {
                    intentType = SurveyCustomerIntentType.WRONG;
                    reason = SurveyCustomerWrongIntentTypeReason.COMMERCIAL_CREDIT;
                    break;
                }
                case SurveyFinancingType.CONSUMER_CREDIT: {
                    intentType = SurveyCustomerIntentType.WRONG;
                    reason = SurveyCustomerWrongIntentTypeReason.CONSUMER_CREDIT;
                    break;
                }
            }

            if (intentType !== SurveyCustomerIntentType.WRONG) {
                if (refinancingDebtLeftAmount < MIN_LOW_INTENT_LOAN_AMOUNT) {
                    intentType = SurveyCustomerIntentType.LOW;
                } else if (currentInterestRate >= OLD_CUSTOMER_HIGH_INTENT_REFINANCING_INTEREST_RATE_THRESHOLD) {
                    intentType = SurveyCustomerIntentType.HIGH;
                } else {
                    intentType = SurveyCustomerIntentType.LOW;
                }
            }

            break;
        }
        case DealPropertyFinancingType.COOPERATIVE_APARTMENT:
        case DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION:
        case DealPropertyFinancingType.INTERIM_FINANCING:
        case DealPropertyFinancingType.RETIREMENT_APARTMENT:
        case DealPropertyFinancingType.PURCHASE: {
            /* Customer intention is to purchase and finished his research */
            const isCustomerInPurchasePhase = SURVEY_PURCHASE_PROJECT_PURCHASE_PROGRESS_VALUES.includes(
                progress as SurveyPurchaseProjectStatus,
            );

            // Check if the only next step is 'STILL_THINKING_ABOUT_IT'
            const isNextStepsUndecided = checkMultiSelectForSingleValue(
                nextStepsInPurchaseProcess,
                SurveyNextStepsInPurchaseProcess.STILL_THINKING_ABOUT_IT,
            );

            const purchaseNotYetConfirmed =
                howSoonFinancingCommitmentNeeded ===
                SurveyHowSoonFinancingCommitmentNeededPurchase.NOT_YET_PURCHASE_NOT_CONFIRMED;

            // Combine conditions to check if customer is undecided and no purchase confirmed
            const customerUndecidedAndNoPurchase = purchaseNotYetConfirmed && isNextStepsUndecided;

            const isQualityReferral = SURVEY_PURCHASE_REFERRAL_VALUES.includes(referral as BorrowerReferralType);

            const isCooperativeApartment = cooperativeApartment === JaNeinValue.JA;

            if (customerUndecidedAndNoPurchase || loanAmount < MIN_LOW_INTENT_LOAN_AMOUNT) {
                intentType = SurveyCustomerIntentType.LOW;
            } else if (isCustomerInPurchasePhase) {
                /* Customer is in buying phase: e.g. wants an offer or has already an offer */

                if (isCooperativeApartment) {
                    intentType = SurveyCustomerIntentType.HIGH;
                } else if (ltv >= HIGH_LTV_MARGIN) {
                    if (isQualityReferral) {
                        intentType = SurveyCustomerIntentType.MEDIUM;
                    } else {
                        intentType = SurveyCustomerIntentType.UNFEASIBLE;
                        reason = SurveyCustomerLowIntentTypeReason.LTV;
                    }
                } else if (dsti >= HIGH_DSTI_MARGIN) {
                    if (isQualityReferral) {
                        intentType = SurveyCustomerIntentType.MEDIUM;
                    } else {
                        intentType = SurveyCustomerIntentType.UNFEASIBLE;
                        reason = SurveyCustomerLowIntentTypeReason.DSTI;
                    }
                } else {
                    intentType = SurveyCustomerIntentType.HIGH;
                }
            } else {
                /* Customer is still in research phase */

                if (isQualityReferral) {
                    intentType = SurveyCustomerIntentType.MEDIUM;
                } else if (ltv >= HIGH_LTV_MARGIN) {
                    intentType = SurveyCustomerIntentType.UNFEASIBLE;
                    reason = SurveyCustomerLowIntentTypeReason.LTV;
                } else if (dsti >= HIGH_DSTI_MARGIN) {
                    intentType = SurveyCustomerIntentType.UNFEASIBLE;
                    reason = SurveyCustomerLowIntentTypeReason.DSTI;
                } else if (propertyAlreadyVisited === JaNeinValue.JA) {
                    intentType = SurveyCustomerIntentType.MEDIUM;
                } else {
                    intentType = SurveyCustomerIntentType.LOW;
                }
            }

            break;
        }
        case DealPropertyFinancingType.CONSTRUCTION: {
            const isConstructionBeyondPreferredTimeline =
                roughConstructionStartDate && OFF_SEASON_CONSTRUCTION_TIMELINE.includes(roughConstructionStartDate);

            if (isConstructionBeyondPreferredTimeline || loanAmount < MIN_LOW_INTENT_LOAN_AMOUNT) {
                intentType = SurveyCustomerIntentType.LOW;
            } else if (progress === SurveyConstructionProjectStatus.LAND_AREA_AVAILABLE) {
                intentType = SurveyCustomerIntentType.HIGH;
            } else {
                intentType = SurveyCustomerIntentType.LOW;
            }
            break;
        }
        default: {
            intentType = SurveyCustomerIntentType.WRONG;
            reason = SurveyCustomerWrongIntentTypeReason.MISCELLANEOUS;
        }
    }

    return { intentType, reason };
};

/**
 * [MOR-1983]
 * Function for determining the intent type of customer.
 *
 * Based on https://docs.google.com/drawings/d/1way2Qg7DnwV1Kl7GA_lMMrCcPPpg40wTHxub02WehbA (purchase)
 * Based on https://docs.google.com/drawings/d/1lPulHIgrjV1GmxWxdGJS2eepAK1Y6l-qU-KF-OQjUVM (refinancing)
 * Based on https://docs.google.com/drawings/d/1vUuJdM_rK52SOxeSkJe1DrvNtmFjutHdFFVxIZUEFF0 (construction)
 *y
 * @param {SurveyCustomerIntentProperties | SurveyProperties} surveyProperties all the necessary fields or all surveyProperties fields
 * at places
 * @return {SurveyCustomerIntentType}
 */
export const getCustomerIntentType = (
    surveyProperties: SurveyCustomerIntentProperties | SurveyProperties,
): SurveyCustomerIntentTypeReturn => {
    if (!surveyProperties) {
        return {
            intentType: SurveyCustomerIntentType.LOW,
        };
    }

    const {
        intention,
        progress,
        usage,
        financingType,
        ltv,
        dsti,
        currentInterestRate,
        referral,
        propertyAlreadyVisited,
        refinancingDebtLeftAmount,
        loanAmount,
        howSoonFinancingCommitmentNeeded,
        roughConstructionStartDate,
        purchaseProgress,
        submissionPlanReady,
        houseBankFinancingPreference,
        constructionCostsAlreadyAvailable,
        constructionEstimateRecieved,
    } = surveyProperties;

    if (usage === SurveyPropertyUsage.COMMERCIAL) {
        return {
            intentType: SurveyCustomerIntentType.WRONG,
            reason: SurveyCustomerWrongIntentTypeReason.COMMERCIAL_USAGE,
        };
    }

    let intentType: SurveyCustomerIntentType | undefined = undefined;
    let reason: SurveyCustomerWrongIntentTypeReason | SurveyCustomerLowIntentTypeReason | undefined = undefined;

    const isQualityReferral = SURVEY_PURCHASE_REFERRAL_VALUES.includes(referral as BorrowerReferralType);

    switch (intention) {
        case DealPropertyFinancingType.CONSUMER_CREDIT: {
            intentType = SurveyCustomerIntentType.WRONG;
            reason = SurveyCustomerWrongIntentTypeReason.CONSUMER_CREDIT;
            break;
        }
        case DealPropertyFinancingType.OTHER: {
            intentType = SurveyCustomerIntentType.WRONG;
            reason = SurveyCustomerWrongIntentTypeReason.OTHER_PROJECT_TYPE;
            break;
        }
        case DealPropertyFinancingType.REFINANCE: {
            switch (financingType) {
                case SurveyFinancingType.COMMERCIAL_CREDIT: {
                    intentType = SurveyCustomerIntentType.WRONG;
                    reason = SurveyCustomerWrongIntentTypeReason.COMMERCIAL_CREDIT;
                    break;
                }
                case SurveyFinancingType.CONSUMER_CREDIT: {
                    intentType = SurveyCustomerIntentType.WRONG;
                    reason = SurveyCustomerWrongIntentTypeReason.CONSUMER_CREDIT;
                    break;
                }
            }

            if (intentType) {
                break;
            }

            if (refinancingDebtLeftAmount < MIN_REFINANCING_LOW_INTENT_LOAN_AMOUNT) {
                intentType = SurveyCustomerIntentType.LOW;
                break;
            }

            if (isQualityReferral) {
                intentType = SurveyCustomerIntentType.MEDIUM;
                break;
            }

            if (ltv >= HIGH_LTV_MARGIN) {
                intentType = SurveyCustomerIntentType.UNFEASIBLE;
                reason = SurveyCustomerLowIntentTypeReason.LTV;
                break;
            }

            if (dsti >= HIGH_DSTI_MARGIN) {
                intentType = SurveyCustomerIntentType.UNFEASIBLE;
                reason = SurveyCustomerLowIntentTypeReason.DSTI;
                break;
            }

            const hasStrongPreferenceForHouseBank =
                houseBankFinancingPreference === SurveyHouseBankFinancingPreference.DESIRED;

            if (hasStrongPreferenceForHouseBank) {
                intentType = SurveyCustomerIntentType.LOW;
                break;
            }

            if (currentInterestRate >= CUSTOMER_HIGH_INTENT_REFINANCING_INTEREST_RATE_THRESHOLD) {
                intentType = SurveyCustomerIntentType.HIGH;
                break;
            }

            intentType = SurveyCustomerIntentType.LOW;
            break;
        }
        case DealPropertyFinancingType.COOPERATIVE_APARTMENT:
        case DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION:
        case DealPropertyFinancingType.INTERIM_FINANCING:
        case DealPropertyFinancingType.RETIREMENT_APARTMENT:
        case DealPropertyFinancingType.PURCHASE: {
            if (loanAmount < MIN_LOW_INTENT_LOAN_AMOUNT) {
                intentType = SurveyCustomerIntentType.LOW;
                break;
            }

            if (isQualityReferral) {
                intentType = SurveyCustomerIntentType.MEDIUM;
                break;
            }

            if (ltv >= HIGH_LTV_MARGIN) {
                intentType = SurveyCustomerIntentType.UNFEASIBLE;
                reason = SurveyCustomerLowIntentTypeReason.LTV;
                break;
            }

            if (dsti >= HIGH_DSTI_MARGIN) {
                intentType = SurveyCustomerIntentType.UNFEASIBLE;
                reason = SurveyCustomerLowIntentTypeReason.DSTI;
                break;
            }

            // Check if purchaseProgress IS NOT JUST 'NO_CONVERSATIONS_HELD'
            const madeAnyPurchaseProgress = !checkMultiSelectForSingleValue(
                purchaseProgress,
                SurveyPurchaseProgress.NO_CONVERSATIONS_HELD,
            );

            // Check if howSoonFinancingCommitmentNeeded IS NOT JUST 'NOT_YET_PURCHASE_NOT_CONFIRMED'
            const needsAQuickOffer =
                howSoonFinancingCommitmentNeeded !==
                SurveyHowSoonFinancingCommitmentNeededPurchase.NOT_YET_PURCHASE_NOT_CONFIRMED;

            const hasNotVisitedButWillSoon =
                propertyAlreadyVisited === SurveyPropertyAlreadyVisited.NO_BUT_HAVE_VIEW_APPOINTMENT;

            const hasVisitedPropertyAlready = propertyAlreadyVisited === SurveyPropertyAlreadyVisited.YES;

            const isForecastHIT = loanAmount > MIN_FORECAST_LOW_INTENT_LOAN_AMOUNT;

            if (hasNotVisitedButWillSoon && madeAnyPurchaseProgress) {
                if (needsAQuickOffer) {
                    intentType = SurveyCustomerIntentType.HIGH;
                    break;
                }

                intentType = isForecastHIT
                    ? SurveyCustomerIntentType.FORECAST_HIGH
                    : SurveyCustomerIntentType.FORECAST_LOW;
                break;
            }

            if (hasVisitedPropertyAlready) {
                if (madeAnyPurchaseProgress) {
                    intentType = SurveyCustomerIntentType.HIGH;
                    break;
                }

                if (needsAQuickOffer) {
                    intentType = SurveyCustomerIntentType.HIGH;
                    break;
                }

                intentType = isForecastHIT
                    ? SurveyCustomerIntentType.FORECAST_HIGH
                    : SurveyCustomerIntentType.FORECAST_LOW;
                break;
            }

            intentType = SurveyCustomerIntentType.LOW;
            break;
        }
        case DealPropertyFinancingType.CONSTRUCTION: {
            if (loanAmount < MIN_LOW_INTENT_LOAN_AMOUNT) {
                intentType = SurveyCustomerIntentType.LOW;
                break;
            }

            if (isQualityReferral) {
                intentType = SurveyCustomerIntentType.MEDIUM;
                break;
            }

            if (ltv >= HIGH_LTV_MARGIN) {
                intentType = SurveyCustomerIntentType.UNFEASIBLE;
                reason = SurveyCustomerLowIntentTypeReason.LTV;
                break;
            }

            if (dsti >= HIGH_DSTI_MARGIN) {
                intentType = SurveyCustomerIntentType.UNFEASIBLE;
                reason = SurveyCustomerLowIntentTypeReason.DSTI;
                break;
            }

            const isLandPlotAvailable = progress === SurveyConstructionProjectStatus.LAND_AREA_AVAILABLE;

            const submissionPlanReadyOrAvailable = SUBMISSION_PLAN_READY_OR_AVAILABLE_VALUES.includes(
                submissionPlanReady as SurveyConstructionSubmissionPlanReady,
            );

            const needsFinancingWithinNext6Months =
                roughConstructionStartDate && ON_SEASON_CONSTRUCTION_TIMELINE.includes(roughConstructionStartDate);

            const constructionCostsStartedOrFinished = CONSTRUCTION_COSTS_ALREADY_AVAILABLE_OR_ALMOST_READY.includes(
                constructionCostsAlreadyAvailable as SurveyConstructionCostsAlreadyAvailable,
            );

            const costEstimatesStartedOrFinished = CONSTRUCTION_COSTS_ESTIMATE_STARTED_OR_FINISHED.includes(
                constructionEstimateRecieved as SurveyConstructionEstimateRecieved,
            );

            if (
                isLandPlotAvailable &&
                submissionPlanReadyOrAvailable &&
                needsFinancingWithinNext6Months &&
                constructionCostsStartedOrFinished &&
                costEstimatesStartedOrFinished
            ) {
                intentType = SurveyCustomerIntentType.HIGH;
                break;
            }

            intentType = SurveyCustomerIntentType.LOW;
            break;
        }
        default: {
            intentType = SurveyCustomerIntentType.WRONG;
            reason = SurveyCustomerWrongIntentTypeReason.MISCELLANEOUS;
        }
    }

    return { intentType, reason };
};

/**
 * Use this function to determine the intention of the user, such as
 * purchase, refinancing, construction or any other. This function
 * does not qualify the intent, use @link {getCustomerIntentType} for
 * qualifying if an intent is low, mid or high.
 *
 * @param {string} intention
 * @param {string} progress
 * @param {string} usage
 * @param {string} financing
 *
 * @return {DealPropertyFinancingType | AnyProjectIntentType} returns a value of DealPropertyFinancingType
 * to categorize the intent of the user else AnyProjectIntentType if no categorization is possible.
 */
export const getUserProjectIntentType = (
    intention: string,
    progress: string,
    usage: string,
    financing: string,
): UserProjectIntentType => {
    const ANY: UserProjectIntentType = "any";

    switch (intention) {
        case DealPropertyFinancingType.COOPERATIVE_APARTMENT:
        case DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION:
        case DealPropertyFinancingType.INTERIM_FINANCING:
        case DealPropertyFinancingType.RETIREMENT_APARTMENT:
        case DealPropertyFinancingType.PURCHASE: {
            return progress && isOwnUsageOrRent(usage) && isBuying(progress as SurveyPurchaseProjectStatus)
                ? (intention as DealPropertyFinancingType) // not DealPropertyFinancingType.PURCHASE as it can be any of the above
                : ANY;
        }
        case DealPropertyFinancingType.CONSTRUCTION: {
            return isOwnUsageOrRent(usage) &&
                (progress as SurveyConstructionProjectStatus) === SurveyConstructionProjectStatus.LAND_AREA_AVAILABLE
                ? DealPropertyFinancingType.CONSTRUCTION
                : ANY;
        }
        case DealPropertyFinancingType.REFINANCE: {
            return financing === SurveyFinancingType.REAL_ESTATE_CREDIT ? DealPropertyFinancingType.REFINANCE : ANY;
        }
        case DealPropertyFinancingType.CONSUMER_CREDIT: {
            return DealPropertyFinancingType.CONSUMER_CREDIT;
        }
        case DealPropertyFinancingType.OTHER: {
            return DealPropertyFinancingType.OTHER;
        }
        default:
            return ANY;
    }
};

export function isCustomerIntentHighOrMedium(customerIntent: string) {
    return (
        isHighCustomerIntentGroup(customerIntent as SurveyCustomerIntentType) ||
        customerIntent === SurveyCustomerIntentType.MEDIUM
    );
}

function isOwnUsageOrRent(usage: string) {
    return usage === SurveyPropertyUsage.OWN_USE || usage === SurveyPropertyUsage.RENT_OUT;
}

function isBuying(progress: string) {
    return (
        progress === SurveyPurchaseProjectStatus.ACTIVE_ON_SIGHTSEEING ||
        progress === SurveyPurchaseProjectStatus.WANT_TO_MAKE_AN_OFFER ||
        progress === SurveyPurchaseProjectStatus.PURCHASE_OFFER_ALREADY_MADE
    );
}

export const mapDealPropertiesToSurveyCustomerIntentProperties = (
    dealProperties: DealProperties,
): SurveyCustomerIntentProperties => {
    return {
        intention: dealProperties.surveyIntention,
        progress: dealProperties.needOfCustomerSurvey,
        usage: dealProperties.surveyUsage,
        financingType: dealProperties.surveyFinancingType,
        ltv: dealProperties.ltvSurvey,
        dsti: dealProperties.dstiSurvey,
        cooperativeApartment: dealProperties.surveyCooperativeApartment,
        currentInterestRate: dealProperties.currentInterestRate,
        referral: dealProperties.referral,
        propertyAlreadyVisited: dealProperties.surveyPropertyAlreadyVisited,
        refinancingDebtLeftAmount: dealProperties.surveyRefinancingDebtLeftAmount,
        loanAmount: dealProperties.loanAmount,
        howSoonFinancingCommitmentNeeded: dealProperties.surveyHowSoonFinancingCommitmentNeeded,
        nextStepsInPurchaseProcess: dealProperties.surveyNextStepsInPurchaseProcess,
        roughConstructionStartDate: dealProperties.surveyRoughConstructionStartDate,
        purchaseProgress: dealProperties.surveyPurchaseProgress,
        submissionPlanReady: dealProperties.surveySubmissionPlanReady,
        houseBankFinancingPreference: dealProperties.surveyHouseBankFinancingPreference,
        constructionCostsAlreadyAvailable: dealProperties.surveyConstructionCostsAlreadyAvailable,
        constructionEstimateRecieved: dealProperties.surveyConstructionEstimateRecieved,
    };
};

/**
 *
 * @param {string} values comma separated values, f.e. val1;val2;val3;...
 * @param {string} valueToCompare the single value we're comparing the values with
 * @returns true if the length of values is 1 (single pick) and if that value matches the valueToCompare
 * f.e for values of val1;val2;val3; and valueToCompare of val1 it is false as there are multiple values
 * f.e. for values of val2 and valueToCompare of val1 it is false as there is one value, but it doesn't match
 * f.e. for values of val1 and valueToCompare of val1 it would return true as it's just one value and it matches
 */
const checkMultiSelectForSingleValue = (values: string, valueToCompare: string) => {
    const valuesSplit = values?.split(";");
    return valuesSplit?.length === 1 && valuesSplit?.[0] === valueToCompare;
};

export function getCustomerName(deal: Pick<DealProperties, "firstNameBorrower1" | "lastNameBorrower1">) {
    if (!deal) {
        return "";
    }

    return `${deal.firstNameBorrower1 || ""} ${deal.lastNameBorrower1 || ""}`.trim();
}
