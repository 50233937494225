import { DealProperties, StorageFile, TrueFalseValue, WithTimestamps } from ".";
import {
    bankDocumentTypes,
    banks,
    categories,
    documentTypes,
    miraclTypes,
    objectDocumentTypes,
    objects,
    personalDocumentTypes,
    persons,
} from "../constants/document";
import { Status } from "./status";

/**
 * Because of firebase, we enable null values to be able to
 * set desired values to no value (because undefined is not supported)
 */
export interface DocumentProperties {
    categoryId?: string | null;
    typeId?: string | null;
    bankId?: string | null;
    personId?: string | null;
    month?: number | null;
    allMonthsIncluded?: boolean;
    year?: number | null;
    objectId?: string | null;
    requiredDocumentJobType?: string | null;
    liabilityId?: string | null;
}

export type DocumentQualityChecklist = {
    valid: TrueFalseValue | null;
    inColor: TrueFalseValue | null;
    noSecondaryResidenceAvailable: TrueFalseValue | null;
    noGarnishmentOfWages: TrueFalseValue | null;
    noPerDiemsAndExpensesIncluded: TrueFalseValue | null;
    noCompanyCar: TrueFalseValue | null;
    noBusinessLoans: TrueFalseValue | null;
    startingAndEndingBalanceVisible: TrueFalseValue | null;
    noAdditionalCredits: TrueFalseValue | null;
    noSignsOfGamblingAddiction: TrueFalseValue | null;
    noReturnDebits: TrueFalseValue | null;
    accountInPlus: TrueFalseValue | null;
    noAdditionalAlimony: TrueFalseValue | null;
    nameVisible: TrueFalseValue | null;
    ibanVisible: TrueFalseValue | null;
    heightVisible: TrueFalseValue | null;
    currencyVisible: TrueFalseValue | null;
    noIncreasedInterruptions: TrueFalseValue | null;
    outsideProbationaryPeriod: TrueFalseValue | null;
    newEntryDate: TrueFalseValue | null;
    numberOfHoursPerWeek: TrueFalseValue | null;
    salaryVisible: TrueFalseValue | null;
    bankingInstitution: TrueFalseValue | null;
    loanAmount: TrueFalseValue | null;
    totalRunningTime: TrueFalseValue | null;
    intendedUse: TrueFalseValue | null;
    rate: TrueFalseValue | null;
    contractsComplete: TrueFalseValue | null;
    noIncomeDecreased: TrueFalseValue | null;
    noWorseThanLastYear: TrueFalseValue | null;
    noResidue: TrueFalseValue | null;
    noticeOfOccupationalDisability: TrueFalseValue | null;
    noSchufaEntries: TrueFalseValue | null;
    noAlimony: TrueFalseValue | null;
    compensationAmount: TrueFalseValue | null;
    propertyOwnershipEstablished: TrueFalseValue | null;
    dimensioned: TrueFalseValue | null;
    everyAreaValueReadable: TrueFalseValue | null;
    atLeastThreePages: TrueFalseValue | null;
    apartmentVisible: TrueFalseValue | null;
    exteriorSurfacesVisible: TrueFalseValue | null;
    noDeviationsFromFloorPlan: TrueFalseValue | null;
    carParkingSpaceVisible: TrueFalseValue | null;
    basementVisible: TrueFalseValue | null;
    penalties: TrueFalseValue | null;
    rentalContractLimited: TrueFalseValue | null;
    noBufferSpecified: TrueFalseValue | null;
    noFurniture: TrueFalseValue | null;
};

export type DocumentQualityChecklistOptionKey = keyof DocumentQualityChecklist;

export type DocumentQualityChecklistOptionType = "checkbox" | "number";

export type DocumentQualityChecklistOption = {
    name: DocumentQualityChecklistOptionKey;
    type: DocumentQualityChecklistOptionType;
    documentTypes: string[];
};

export interface Document {
    // unique ID in Database
    id: string;
    // unique ID of deal in Hubspot
    dealId: string;
    // unique ID in Storage
    fileId: string;
    // per-deal unique ID to identify a document at its source (e.g. PandaDoc)
    sourceId?: string;
    // name to show to users
    name: string;
    // mimeType of underlying file
    type?: string | null;
    properties?: DocumentProperties;
    notes?: string;
    status?: Status;
    pandadocUrl?: string;
    qualityChecklist?: Partial<DocumentQualityChecklist>;
    uncompressedFile?: StorageFile | null;
    suggestions?: Partial<DealProperties>;
    suggestionsBeingGenerated?: boolean;
    suggestionsGenerationFailed?: boolean;
    suggestionsPollingTaskId?: string;
}

export enum UploadedDocumentVariant {
    UNCOMPRESSED = "uncompressed",
    COMPRESSED = "compressed",
}

export type TimestampedDocument = WithTimestamps<Document>;

export type DimensionalDocument = TimestampedDocument & {
    properties: {
        category?: DocumentProperty;
        type?: DocumentProperty;
        bank?: DocumentProperty;
        person?: DocumentProperty;
        object?: DocumentProperty;
    };
    finished?: boolean;
};

export enum DocumentPropertyType {
    CATEGORY = 1,
    BANK = 2,
    TYPE = 3,
    PERSON = 4,
    OBJECT = 5,
}
export type DocumentPropertyMeta = Record<string, unknown>;

export interface DocumentProperty {
    id: string;
    parentId?: string;
    /**
     * Property display name
     */
    name: string;
    /**
     * Property unique value
     */
    value?: string | number;
    /**
     * Property type (DocumentPropertyType)
     */
    type: DocumentPropertyType;
    /**
     * Property order priority
     */
    order?: number;
    /**
     * Property status
     */
    status?: Status;
}

export type DocumentPropertiesById = Record<string, DocumentProperty>;
export type DocumentPropertiesByType = Partial<Record<DocumentPropertyType, DocumentProperty[] | undefined>>;
export type DocumentPropertiesByValue = Record<Exclude<DocumentProperty["value"], undefined>, DocumentProperty[]>;

export type RequiredDocumentProperties = {
    id?: string;
    parentSubCategory?: string;
    lastNMonths?: number;
    lastNYears?: number;
    requiredDocumentAmount?: { label: string; amount: number };
    requiredDocumentJobType?: RequiredDocumentJobType;
    liabilityId?: string;
} & Pick<DocumentProperties, "typeId" | "objectId" | "personId">;

export type RequiredDocuments<T extends Document | TimestampedDocument = TimestampedDocument> = Partial<
    Record<
        string,
        Record<
            DocumentProperty["id"],
            {
                lastNMonths?: RequiredDocumentProperties["lastNMonths"];
                lastNYears?: RequiredDocumentProperties["lastNYears"];
                requiredDocumentAmount?: RequiredDocumentProperties["requiredDocumentAmount"];
                requiredDocumentJobType?: RequiredDocumentProperties["requiredDocumentJobType"];
                liabilityId?: RequiredDocumentProperties["liabilityId"];
                files: T[];
            }
        >
    > & {
        undefined?: { files: T[] };
    }
>;

export enum RequiredDocumentJobType {
    MAIN = "main",
    ADDITIONAL_1 = "additional1",
    ADDITIONAL_2 = "additional2",
}

export type Category = keyof typeof categories;
export type Bank = keyof typeof banks;
export type Person = keyof typeof persons;
export type DocumentType = keyof typeof documentTypes;
export type BankDocumentType = keyof typeof bankDocumentTypes;
export type PersonalDocumentType = keyof typeof personalDocumentTypes;
export type ObjectDocumentType = keyof typeof objectDocumentTypes;
export type ObjectType = keyof typeof objects;
export type MiraclType = keyof typeof miraclTypes;
